import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

const useStyles = createUseStyles({
  header: ({
    scrollPosition = 0,
    forceMobile = false,
  }: {
    scrollPosition?: number;
    forceMobile?: boolean;
  }) => {
    const minHeight = 100;
    const maxHeight = 350;
    const heightBasedOnScrollPosition = maxHeight - scrollPosition * maxHeight;

    const backgroundOpacityMaxScrollPosition = 0.3;
    const backgroundOpacity =
      Math.min(scrollPosition, backgroundOpacityMaxScrollPosition) /
      backgroundOpacityMaxScrollPosition;
    return {
      display: "flex",
      position: "fixed",
      width: "100%",
      height: forceMobile
        ? minHeight
        : Math.max(minHeight, heightBasedOnScrollPosition),
      justifyContent: "center",
      alignItems: "center",
      background: `rgba(0, 0, 0, ${backgroundOpacity})`,
      transition: "background 0.5s, height 0.5s",
      "@media (max-width: 1000px) or (max-height: 500px)": {
        height: minHeight,
      },
      zIndex: 1000,
    };
  },
  bandLogoContainer: {
    flex: [1, 1],
    width: "100%",
    height: "100%",
  },
  bandLogo: {
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
    transition: "maxWidth 0.5s",
    "@media (max-width: 1000px)": {
      maxWidth: 200,
    },
  },
  navigationMenuButtonContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    height: 100,
    alignItems: "center",
  },
  navigationMenuButton: {
    background: "none",
    color: "inherit",
    border: "none",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    margin: 15,
  },
  navigationMenuIcon: {
    padding: 10,
    width: 24,
    height: 24,
    objectFit: "scale-down",
  },
});

const getRelativeScrollPosition = () => {
  const verticalScrollPosition = window.scrollY;
  const scrollableHeight = document.body.clientHeight - window.innerHeight;
  return verticalScrollPosition / scrollableHeight;
};

interface HeaderProps {
  onNavigationMenuIconClick?: () => void;
  forceMobile?: boolean;
}

const Header = ({ onNavigationMenuIconClick, forceMobile }: HeaderProps) => {
  const [relativeScrollPosition, setRelativeScrollPosition] = useState(
    getRelativeScrollPosition()
  );
  const {
    header,
    bandLogoContainer,
    bandLogo,
    navigationMenuButtonContainer,
    navigationMenuButton,
    navigationMenuIcon,
  } = useStyles({
    scrollPosition: relativeScrollPosition,
    forceMobile,
  });

  useEffect(() => {
    const onScroll = () =>
      setRelativeScrollPosition(getRelativeScrollPosition());
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <div className={header}>
      <div style={{ flex: 1 }} />
      {/* TODO: Fix style issues with another class */}
      <Link className={bandLogoContainer} to="/">
        <img className={bandLogo} src="./imgs/logo-white.png" />
      </Link>
      <div style={{ flex: 1 }} />
      <div className={navigationMenuButtonContainer}>
        <button
          className={navigationMenuButton}
          onClick={onNavigationMenuIconClick}
        >
          <img className={navigationMenuIcon} src="./imgs/icons8-menu-24.png" />
        </button>
      </div>
    </div>
  );
};

export default Header;
