import React from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";

import Router from "./components/Router";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyA0Iu5k9Jko5J-7DThFuaS39uy4Gy1IZu0",
  authDomain: "ready-stranger-website.firebaseapp.com",
  projectId: "ready-stranger-website",
  storageBucket: "ready-stranger-website.appspot.com",
  messagingSenderId: "249407511509",
  appId: "1:249407511509:web:29524bc4e3f926f92fbb5c",
  measurementId: "G-WMGM3XQCZM",
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); // TODO: Connect with Firebase Functions
