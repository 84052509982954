import { RouterProvider, createHashRouter, redirect } from "react-router-dom";

import HomePage from "../pages/HomePage";
import AboutUsPage from "../pages/AboutUsPage";
import SongPage from "../pages/SongPage";
import NewReleasePage from "../pages/NewReleasePage";

export type ReleaseMetadata = {
  songName: string;
  spotifyLink: string;
};

// lol move this to a database record
const releaseMetadata: Record<string, ReleaseMetadata> = {
  elv: {
    songName: "En La Vacía",
    spotifyLink:
      "https://open.spotify.com/track/5S6EKMTj4H3vuZe0J3nRf7?si=47e78bcce6a44f27",
  },
};

const router = createHashRouter([
  { id: "home", Component: HomePage, path: "*" },
  { id: "about", Component: AboutUsPage, path: "/about" },
  { id: "music", Component: SongPage, path: "/music" },
  {
    id: "new-music",
    Component: NewReleasePage,
    path: "/music/new",
    loader: ({ request }) => {
      const url = new URL(request.url);
      const releaseName = url.searchParams.get("release-name");

      if (!releaseName) {
        return redirect("/");
      } else if (!releaseMetadata[releaseName]) {
        return redirect("/");
      }

      return [releaseName, releaseMetadata[releaseName]];
    },
  },
]);

const routerProvider = () => <RouterProvider router={router} />;

export default routerProvider;
