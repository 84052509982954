import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  contentContainer: {
    display: "flex",
    width: "100%",
    padding: ["5vh", "0vh"],
    color: "#fff",
    background: "#000",
    justifyContent: "center",
  },
  contactItemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  socialMediaLinkContainer: {
    display: "flex",
    padding: 5,
    "& > *": {
      margin: [0, 5],
    },
  },
});

const socialMediaLinks = [
  {
    name: "Instagram",
    src: "./logos/Instagram_Glyph_White.svg",
    link: "https://instagram.com/readystrangerband",
  },
  {
    name: "YouTube",
    src: "./logos/yt_logo_mono_dark.png",
    link: "https://www.youtube.com/@ReadyStranger",
  },
] as const;

const ContactFooter = () => {
  const { contentContainer, contactItemsContainer, socialMediaLinkContainer } =
    useStyles();

  return (
    <div className={contentContainer}>
      <div className={contactItemsContainer}>
        <h2>contact</h2>
        <p>
          <a href="mailto:readystrangerband@gmail.com">
            readystrangerband@gmail.com
          </a>
        </p>
        <div className={socialMediaLinkContainer}>
          {socialMediaLinks.map((socialMediaLink) => {
            return (
              <a
                key={socialMediaLink.name}
                href={socialMediaLink.link}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  height="30"
                  alt={`Band ${socialMediaLink.name} account`}
                  src={socialMediaLink.src}
                />
              </a>
            );
          })}
        </div>
        <p>
          <span>
            cover photo by{" "}
            <a
              href="https://instagram.com/thecherrieberry"
              target="_blank"
              rel="noreferrer"
            >
              Chris Cherrie
            </a>
          </span>
          <br />
          <span>
            all other photos by{" "}
            <a
              href="https://instagram.com/_fabreeze"
              target="_blank"
              rel="noreferrer"
            >
              Fab Lopez
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default ContactFooter;
