import React, { PropsWithChildren, createRef, useState } from "react";
import { createUseStyles } from "react-jss";

import ContactFooter from "../components/ContactFooter";
import Header from "../components/Header";
import { Link } from "react-router-dom";

interface UseStylesProps {
  image?: string;
  showNavigation?: boolean;
}

const useStyles = createUseStyles({
  backgroundImageContent: ({ image }: UseStylesProps) => {
    return {
      display: "flex",
      width: "100%",
      height: "100vh",
      background: image
        ? `url(https://firebasestorage.googleapis.com/v0/b/ready-stranger-website.appspot.com/o/images%2F${image}.jpg?alt=media)`
        : "#000",
      backgroundSize: "cover",
      backgroundPosition: "center",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "inset 0 -1em 1em #000",
    };
  },
  landerText: () => ({}),
  sideNavigation: ({ showNavigation }: UseStylesProps) => ({
    position: "fixed",
    width: "100%",
    height: "100vh",
    background: "#000",
    opacity: showNavigation ? 1 : 0,
    visibility: showNavigation ? "visible" : "hidden",
    transition: ["opacity 0.5s"],
  }),
  sideNavigationContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 100,
    listStyle: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
});

interface PageLayoutProps extends PropsWithChildren {
  backgroundImageName?: string;
  fixedHeader?: boolean;
  landerText?: string;
  showContactFooter?: boolean;
  customLanderComponent?: React.ReactNode;
}

const PageLayout = ({
  children,
  backgroundImageName,
  fixedHeader,
  landerText,
  showContactFooter = false,
  customLanderComponent,
}: PageLayoutProps) => {
  const childrenRef = createRef<HTMLDivElement>();
  const [showNavigation, setShowNavigation] = useState(false);
  const styles = useStyles({
    image: backgroundImageName,
    showNavigation,
  });

  const scrollToChildren = () => {
    childrenRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className={styles.sideNavigation}>
        <ul className={styles.sideNavigationContainer}>
          {[
            { to: "/", name: "Home" },
            { to: "/about", name: "About Us" },
            { to: "/music", name: "Music" },
          ].map((link) => (
            <li>
              <h1>
                <Link to={link.to}>{link.name}</Link>
              </h1>
            </li>
          ))}
        </ul>
      </div>

      <Header
        onNavigationMenuIconClick={() => setShowNavigation(!showNavigation)}
        forceMobile={
          showNavigation || fixedHeader === true || landerText !== undefined
        }
      />
      <>
        {(backgroundImageName || landerText) && (
          <div className={styles.backgroundImageContent}>
            {landerText && (
              <a onClick={scrollToChildren}>
                <h1 className={styles.landerText}>{landerText}</h1>
              </a>
            )}
            {customLanderComponent}
          </div>
        )}
        <div ref={childrenRef}>{children}</div>
        {showContactFooter && <ContactFooter />}
      </>
    </>
  );
};

export default PageLayout;
