import React from "react";
import { useLoaderData } from "react-router-dom";

import PageLayout from "../components/PageLayout";
import { ReleaseMetadata } from "../components/Router";

const NewReleasePage = () => {
  const [releaseName, { songName, spotifyLink }] = useLoaderData() as [
    string,
    ReleaseMetadata
  ];

  return (
    <PageLayout
      backgroundImageName={releaseName}
      customLanderComponent={
        <>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,.8)",
              borderRadius: 6,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "5%",
              maxWidth: "80%",
            }}
          >
            <h1>{songName}</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexShrink: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <button
                style={{
                  background: "none",
                  color: "inherit",
                  border: "none",
                  padding: 0,
                  font: "inherit",
                  cursor: "pointer",
                  outline: "inherit",
                }}
                onClick={() => {
                  // @ts-ignore
                  fbq("trackCustom", "NewReleasePageSpotifyLinkClicked", {
                    songName,
                    releaseName,
                  });
                  window.open(spotifyLink, "_blank");
                }}
              >
                <img
                  height="30vh"
                  alt="New release stream link"
                  src={"./logos/Spotify_Logo_RGB_White.png"}
                />
              </button>
            </div>
          </div>
        </>
      }
      fixedHeader
    />
  );
};

export default NewReleasePage;
