import React from "react";
import { createUseStyles } from "react-jss";

import PageLayout from "../components/PageLayout";

const useStyles = createUseStyles({
  section: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  peopleContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    "@media (max-width: 1500px) or (max-height: 500px)": {
      flexDirection: "column",
    },
  },
  personContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 40,
  },
  personContainerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

const AboutUsPage = () => {
  const styles = useStyles();

  return (
    <PageLayout
      backgroundImageName="TREE"
      landerText="About Us"
      showContactFooter
    >
      {/* <div className={styles.section}>
        <p>Ready, Stranger?</p>
      </div> */}
      <div className={styles.section}>
        <h1>Band Members</h1>
        <div className={styles.peopleContainer}>
          {[
            { name: "Alex" },
            { name: "Jackson" },
            { name: "Kirin" },
            { name: "Leon" },
            { name: "Randy" },
          ].map((person) => (
            <div className={styles.personContainer}>
              <div className={styles.personContainerContent}>
                <div
                  style={{
                    width: 150,
                    height: 150,
                    borderRadius: 75,
                    background: `url(https://firebasestorage.googleapis.com/v0/b/ready-stranger-website.appspot.com/o/images%2F${person.name}.jpg?alt=media)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <h2>{person.name}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};

export default AboutUsPage;
