import React from "react";
import { createUseStyles } from "react-jss";

import PageLayout from "../components/PageLayout";

const useStyles = createUseStyles({
  section: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  musicContainer: {
    display: "flex",
    justifyContent: "center",
    width: "80%",
    gap: "10%",
    "@media (max-width: 1500px) or (max-height: 500px)": {
      flexDirection: "column",
      gap: "24px",
    },
  },
  musicProviderContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& > *": {
      borderRadius: 12,
      overflow: "hidden",
    },
  },
});

const appleMusicSongs = [
  "https://embed.music.apple.com/us/album/soulrain/1677931984?i=1677931987",
  "https://embed.music.apple.com/us/album/balthasar-ruxten/1687030170?i=1687030171",
  "https://embed.music.apple.com/us/album/kickflipping-into-the-void/1700272065?i=1700272397",
];

const SongPage = () => {
  const styles = useStyles();

  return (
    <PageLayout
      backgroundImageName="pedal-board"
      landerText="Music"
      showContactFooter
    >
      <div className={styles.section} style={{ marginTop: "10vh" }}>
        <div className={styles.musicContainer}>
          <div className={styles.musicProviderContainer}>
            <iframe
              src="https://open.spotify.com/embed/artist/2jCYIz0iE51LoU9ae79cTl?utm_source=generator"
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            />
          </div>
          <div className={styles.musicProviderContainer}>
            {appleMusicSongs.map((embedLink) => (
              <iframe
                allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                frameBorder="0"
                width="100%"
                height="200"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                loading="lazy"
                src={embedLink}
              />
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default SongPage;
